import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { t } from 'i18next';
import { Button } from 'react-bootstrap';
import { LoadingButton } from 'components/shared/LoadingButton';
import { Bucket } from '../types';
import RefillForm from '../RefillModals/RefillForm';

interface ExpiredDrugRowProps {
  refillBucketId: string;
  setRefillBucketId: (id: string) => void;
  handledExpiredIds: string[];
  bucket: Bucket;
  bucketUnloading: string | null;
  drugs: any;
  startRefill: (id: string) => void;
  refillComplete: (bucket: Bucket) => void;
  emptyAndUnload: (bucketId: string) => void;
  handleDeactivation: (bucketId: string) => void;
}

const ExpiredDrugRow = ({
  bucket,
  bucketUnloading,
  refillBucketId,
  handledExpiredIds,
  drugs,
  startRefill,
  refillComplete,
  setRefillBucketId,
  emptyAndUnload,
  handleDeactivation,
}: ExpiredDrugRowProps) => {
  const handleNameClick = () => {
    if (refillBucketId === bucket.id) {
      setRefillBucketId('');
    } else {
      setRefillBucketId(bucket.id);
    }
  };
  return (
    <div>
      <tr>
        <td id="refillDone">
          {handledExpiredIds.includes(bucket.id) && (
            <FontAwesomeIcon icon="check" size="1x" color="green" />
          )}
        </td>
        <td id="bucketPosition">#{bucket.position}</td>
        <td id="bucketDrug">
          <Button variant="link" onClick={handleNameClick}>
            {' '}
            {drugs.find((d) => d.DIN === bucket.DIN)?.name}{' '}
            {drugs.find((d) => d.DIN === bucket.DIN)?.force}{' '}
          </Button>
        </td>
        <td id="bucketDIN"> DIN {bucket.DIN}</td>
        {refillBucketId !== bucket.id && (
          <td id="expirationDate">{bucket.nearestExpirationDate}</td>
        )}
        <td id="refillBtns">
          {refillBucketId !== bucket.id && (
            <div>
              <Button
                disabled={handledExpiredIds.includes(bucket.id)}
                onClick={() => startRefill(bucket.id)}
              >
                {t('empty')}
              </Button>
              <Button style={{ marginLeft: '3%' }} onClick={() => handleDeactivation(bucket.id)}>
                {t('deactivate')}
              </Button>
            </div>
          )}
          {refillBucketId === bucket.id && (
            <div>
              <LoadingButton
                variant="primary"
                onClick={() => emptyAndUnload(bucket.id)}
                loading={bucketUnloading === bucket.id}
              >
                {t('empty')}
              </LoadingButton>
              <Button style={{ marginLeft: '3%' }} onClick={() => handleDeactivation(bucket.id)}>
                {t('deactivate')}
              </Button>
              <Button
                style={{ marginLeft: '3%' }}
                variant="outline-secondary"
                onClick={() => setRefillBucketId('')}
              >
                <FontAwesomeIcon icon="chevron-up" />
              </Button>
            </div>
          )}
        </td>
      </tr>
      {refillBucketId === bucket.id && !handledExpiredIds.includes(bucket.id) && (
        <RefillForm
          show={refillBucketId === bucket.id && !handledExpiredIds.includes(bucket.id)}
          bucket={bucket}
          drugs={drugs}
          submitDisabled={bucket.quantity > 0}
          submitComplete={refillComplete}
        />
      )}
      <hr />
    </div>
  );
};

export default ExpiredDrugRow;

import { LoginAction, LoginRequestPayload, LoginResponsePayload } from './reducers';
import types from './types';

export const loginRequest = (request: LoginRequestPayload): LoginAction => ({
  type: types.LOGIN_REQUEST,
  payload: request,
});

export const loginResponse = (response: LoginResponsePayload): LoginAction => ({
  type: types.LOGIN_RESPONSE,
  payload: response,
});

export const logoutRequest = (): LoginAction => ({
  type: types.LOGOUT_REQUEST,
  payload: undefined,
});

export const logoutResponse = (response): LoginAction => ({
  type: types.LOGOUT_RESPONSE,
  payload: response,
});

export const newExpirationTime = (response: string): LoginAction => ({
  type: types.NEW_EXPIRATION_TIME,
  payload: response,
});

import React, { useEffect, useRef } from 'react';
import { handleHttpResponseError } from 'components/shared/helpers';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Drug } from 'components/drugs/types';
import { FieldArray, Formik, getIn } from 'formik';
import { t } from 'i18next';
import { Alert, Button } from 'react-bootstrap';
import Form from 'react-bootstrap/esm/Form';
import { Dispatch } from 'redux';
import { useDispatch } from 'react-redux';
import { addLot } from 'services/buckets/operations';
import { focusInput } from 'utils/forms';
import * as yup from 'yup';
import { Bucket } from '../types';
import { nextFocus } from '../formHelper';

interface FormValues {
  label: string;
  lots: {
    UPC: string;
    quantity: string;
    lotNumber: string;
    expirationDate: string;
  }[];
}

const initialValues: FormValues = {
  label: '',
  lots: [
    {
      UPC: '',
      quantity: '',
      lotNumber: '',
      expirationDate: '',
    },
  ],
};

interface RefillFormProps {
  show: boolean;
  bucket: Bucket;
  drugs: Drug[];
  submitDisabled: boolean;
  submitComplete: (bucket: Bucket) => void;
}

interface FormSubmitProps {
  values: FormValues;
  setSubmitting: (isSubmitting: boolean) => void;
  setFieldError: (field: string, message: string | undefined) => void;
}

const RefillForm = ({ show, bucket, drugs, submitDisabled, submitComplete }: RefillFormProps) => {
  const inputRef = useRef<HTMLInputElement | null>(null);
  const dispatch = useDispatch<Dispatch<any>>();
  const drug = drugs.find((d) => d.DIN === bucket.DIN);

  useEffect(() => {
    if (show && inputRef.current) {
      inputRef.current.focus();
    }
  }, [show]);

  const formSubmit = async ({ values, setSubmitting, setFieldError }: FormSubmitProps) => {
    setSubmitting(true);

    if (values.label.length <= 4) {
      const scannedPosition = values.label.replace(/^0+/, '').padStart(1, '0');
      if (scannedPosition !== bucket.position) {
        setFieldError('label', t('BucketPositionDoesNotMatch'));
        setSubmitting(false);
        focusInput('label');
        return;
      }
    } else if (values.label.length === 8) {
      if (values.label !== drug?.DIN) {
        setFieldError('label', t('DINDoesNotMatch'));
        setSubmitting(false);
        focusInput('label');
        return;
      }
    } else {
      setFieldError('label', t('invalidLabelFormat'));
      setSubmitting(false);
      focusInput('label');
      return;
    }

    if (values.lots.length === 0) {
      setFieldError('lots', t('enterAtLeastALot'));
      setSubmitting(false);
      focusInput('lots');
      return;
    }

    const incorrectUPCs: number[] = [];
    const incorrectQuantity: number[] = [];
    for (let i = 0; i < values.lots.length; i += 1) {
      if (!bucket.UPCs || !bucket.UPCs.find((x) => x === values.lots[i].UPC)) {
        incorrectUPCs.push(i);
      }

      if (parseInt(values.lots[i].quantity, 10) + bucket.quantity > 5000) {
        incorrectQuantity.push(i);
      }
    }
    if (incorrectUPCs.length !== 0 || incorrectQuantity.length !== 0) {
      for (const error of incorrectUPCs) {
        setFieldError(`lots.${error}.UPC`, t('UPCDoesNotMatch'));
      }
      for (const error of incorrectQuantity) {
        setFieldError(`lots.${error}.quantity`, t('quantityTotalMustBeMax5000'));
      }
      setSubmitting(false);
      return;
    }

    try {
      await addLot(bucket.id, values.lots)(dispatch);
      setSubmitting(false);
      submitComplete(bucket);
    } catch (err) {
      handleHttpResponseError(err, 'FAILED TO ADD LOT', setFieldError);
    }

    setSubmitting(false);
  };
  const schema = yup.object().shape({
    label: yup
      .string()
      .max(8, t('invalidLabelFormat'))
      .matches(/^[0-9]+$/, t('invalidLabelFormat'))
      .required(t('pleaseScanTheLabel')),
    lots: yup.array().of(
      yup.object({
        UPC: yup
          .string()
          .min(12, t('UPCMustBeAtLeast12charsLong'))
          .max(13, t('UPCMustBeAtMost13charsLong'))
          .matches(/^[0-9]+$/, t('fieldOnlyAcceptNumbers'))
          .required(t('pleaseEnterUPC')),
        quantity: yup
          .number()
          .required(t('pleaseEnterQuantity'))
          .integer(t('wholeQtyOnly'))
          .min(1, t('quantityMustBeAtLeast1'))
          .max(5000, t('quantityMustBeMax5000')),

        lotNumber: yup
          .string()
          .matches(/^[-0-9a-zA-Z]+$/, t('fieldOnlyAcceptAlphanumeric'))
          .required(t('pleaseEnterLotNumber')),
        expirationDate: yup
          .date()
          .required(t('pleaseEnterExpirationDate'))
          .min(new Date(), t('passedDate')),
      }),
    ),
  });

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={schema}
      onSubmit={(values, { setSubmitting, setFieldError }) => {
        formSubmit({ values, setSubmitting, setFieldError });
      }}
    >
      {({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting }) => (
        <Form onSubmit={handleSubmit}>
          <Form.Group className="mb-3" controlId="label">
            <Form.Label>{t('label')}</Form.Label>
            <Form.Control
              ref={inputRef}
              type="text"
              name="label"
              value={values.label}
              onBlur={handleBlur}
              onChange={(e) => {
                handleChange(e);
                if (e.target.value.length === 8) {
                  nextFocus('lots.0.UPC');
                }
              }}
              placeholder={t('scanTheBarcodeOnChronopacBucket')}
              isInvalid={!!errors.label}
            />
            {drug?.deprecatedDINs?.includes(values.label) && (
              <div style={{ color: 'orange' }}>{t('deprecatedDINWarning')}</div>
            )}
            <Form.Control.Feedback type="invalid">{errors.label}</Form.Control.Feedback>
          </Form.Group>
          <div>
            {values.lots.length === 0 && <Alert variant="warning">{t('enterAtLeastALot')}</Alert>}
            <FieldArray name="lots">
              {({ remove, push }) => (
                <div>
                  {values.lots.length > 0 &&
                    values.lots.map((lot, index) => {
                      const upc = `lots.${index}.UPC`;
                      const qty = `lots.${index}.quantity`;
                      const lotNumber = `lots.${index}.lotNumber`;
                      const expDate = `lots.${index}.expirationDate`;

                      return (
                        <div key={index}>
                          <Form.Group className="row" controlId="lots">
                            <div className="input-section">
                              <div className="upper-row">
                                <Form.Group className="mb-3 upc-field" controlId={upc}>
                                  <Form.Label>{t('UPC')}</Form.Label>
                                  <Form.Control
                                    type="text"
                                    name={upc}
                                    value={values.lots[index].UPC}
                                    onBlur={handleBlur}
                                    onChange={(e) => {
                                      handleChange(e);
                                      if (e.target.value.length === 12) {
                                        nextFocus(`lots.${index}.quantity`);
                                      }
                                    }}
                                    placeholder={t('scanTheBarcodeOnDrugContainer')}
                                    isInvalid={getIn(touched, upc) && !!getIn(errors, upc)}
                                  />
                                  <Form.Control.Feedback type="invalid">
                                    {getIn(errors, upc)}
                                  </Form.Control.Feedback>
                                </Form.Group>
                                <Form.Group className="mb-3 qty-field" controlId={qty}>
                                  <Form.Label>
                                    {t('quantity')} ({t('actual')} : {bucket.quantity})
                                  </Form.Label>
                                  <Form.Control
                                    type="number"
                                    min="1"
                                    name={qty}
                                    value={values.lots[index].quantity}
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    placeholder={`${t('enterQuantity')} 1-5000`}
                                    isInvalid={getIn(touched, qty) && !!getIn(errors, qty)}
                                  />
                                  <Form.Control.Feedback type="invalid">
                                    {getIn(errors, qty)}
                                  </Form.Control.Feedback>
                                </Form.Group>
                              </div>
                              <div className="lower-row">
                                <Form.Group className="mb-3 lotnum-field" controlId={lotNumber}>
                                  <Form.Label>{t('lotNumber')}</Form.Label>
                                  <Form.Control
                                    type="text"
                                    name={lotNumber}
                                    value={values.lots[index].lotNumber}
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    placeholder={t('enterLotNumber')}
                                    isInvalid={
                                      getIn(touched, lotNumber) && !!getIn(errors, lotNumber)
                                    }
                                  />
                                  <Form.Control.Feedback type="invalid">
                                    {getIn(errors, lotNumber)}
                                  </Form.Control.Feedback>
                                </Form.Group>

                                <Form.Group className="mb-3 exp-field" controlId={expDate}>
                                  <Form.Label>{t('expirationDate')}</Form.Label>
                                  <Form.Control
                                    type="month"
                                    name={expDate}
                                    value={values.lots[index].expirationDate}
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    placeholder={t('enterExpirationDate')}
                                    max="2999-12"
                                    isInvalid={getIn(touched, expDate) && !!getIn(errors, expDate)}
                                  />
                                  <Form.Control.Feedback type="invalid">
                                    {getIn(errors, expDate)}
                                  </Form.Control.Feedback>
                                </Form.Group>
                              </div>
                            </div>
                            <Button
                              className=" remove-button"
                              variant="outline-secondary"
                              onClick={() => remove(index)}
                            >
                              <FontAwesomeIcon icon="minus" />
                            </Button>
                          </Form.Group>
                        </div>
                      );
                    })}
                  <Button
                    className="col-3 mb-3"
                    variant="outline-primary"
                    onClick={() =>
                      push({
                        UPC: '',
                        quantity: '',
                        lotNumber: '',
                        expirationDate: '',
                      })
                    }
                  >
                    {t('addLot')}
                  </Button>
                </div>
              )}
            </FieldArray>
          </div>
          <Button variant="primary" type="submit" disabled={isSubmitting || submitDisabled}>
            {t('submit')}
          </Button>
        </Form>
      )}
    </Formik>
  );
};

export default RefillForm;

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { t } from 'i18next';
import React, { useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { toggleManuallyDistributedDrug } from 'services/orders/endpoints';
import { RootState } from 'services/store';
import { DrugOrderStatus, Order } from '../types';
import './style.scss';
import { calculateTotalQuantity } from '../helpers';

interface ExclusionInTrayModalProps {
  bucketDIN: string | null;
  orders: Order[];
  close: () => void;
}

const ExclusionInTrayModal = ({ bucketDIN, orders, close }: ExclusionInTrayModalProps) => {
  const [excludedDistributedId, setExcludedDistributedId] = useState<string[]>([]);
  const drugs = useSelector((state: RootState) => state.drugs);
  const drug = drugs.find((d) => d.DIN === bucketDIN);

  const updateOrdersDrug = (orderId: string) => {
    toggleManuallyDistributedDrug(orderId, bucketDIN!);
    setExcludedDistributedId([...excludedDistributedId, orderId]);
  };

  const handleClose = () => {
    setExcludedDistributedId([]);
    close();
  };
  return (
    <Modal show={orders.length > 0}>
      <Modal.Header>
        <Modal.Title>
          {' '}
          {t('warningTrayWithExclusions', {
            drugName: drug?.name,
            drugForce: drug?.force,
            drugDIN: bucketDIN,
          })}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <table className="new-exclusions-table">
          {' '}
          <thead>
            <tr>
              <th> </th>
              <th>#</th>
              <th className="patient-cell">Patient</th>{' '}
              <th className="tray-cell">
                <FontAwesomeIcon icon="inbox" size="1x" color="grey" />
              </th>
            </tr>
          </thead>
          {orders.map((order) => (
            <tr>
              <td>
                {excludedDistributedId.includes(order.id) && (
                  <FontAwesomeIcon icon="check" size="1x" color="green" />
                )}{' '}
              </td>
              <td>#{order.lookupId}</td>
              <td> {order.patient}</td>{' '}
              <td>
                {' '}
                {order.trayIds[0]} {order.trayIds.length === 2 ? <> & {order.trayIds[1]}</> : <></>}
              </td>
              {order.drugs
                .filter(
                  (d) =>
                    d.DIN === bucketDIN &&
                    d.distributions.some(
                      (distribution) => distribution.status === DrugOrderStatus.UNDISTRIBUTED,
                    ),
                )
                .map((d) => (
                  <>
                    {t('qtyToDistribute', {
                      qty: calculateTotalQuantity(d).toString(),
                    })}
                    <td>
                      {!excludedDistributedId.includes(order.id) && (
                        <Button onClick={() => updateOrdersDrug(order.id)}>{t('complete')}</Button>
                      )}
                    </td>
                  </>
                ))}
            </tr>
          ))}
        </table>
      </Modal.Body>
      <Modal.Footer>
        <Button disabled={excludedDistributedId.length !== orders.length} onClick={handleClose}>
          Terminer
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ExclusionInTrayModal;

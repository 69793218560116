import React, { useEffect, useState } from 'react';
import { Dispatch } from 'redux';
import { useDispatch, useSelector } from 'react-redux';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { useTranslation } from 'react-i18next';
import ResourcesHandler from 'components/ResourcesHandler';
import BucketsTable from 'components/buckets/BucketsTable';
import { RootState } from 'services/store';
import { UserPermissions } from 'components/users/types';
import { useCheckPermissions, checkIsAdmin, checkIsChronoTech } from 'utils/permissions';
import { loadBucket, fetchBuckets } from 'services/buckets/operations';
import { fetchDrugs } from 'services/drugs/operations';
import { LoadingButton } from 'components/shared/LoadingButton';
import { HttpResponseError } from 'services/api';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import RefillBucketsModal from 'components/buckets/RefillModals/RefillBucketsModal';
import { getSocketAsync } from 'services/sockets';
import { Order } from 'components/orders/types';
import ExclusionInTrayModal from 'components/orders/Modals/ExclusionInTrayModal';
import ImportBucketListModal from 'components/buckets/ImportBucketListModal';
import { fetchOrganizations } from 'services/organizations/operations';
import { fetchUsers } from 'services/users/operations';
import './style.scss';

const BucketsPage = (): JSX.Element => {
  const dispatch = useDispatch<Dispatch<any>>();
  const buckets = useSelector((state: RootState) => state.buckets);
  const drugs = useSelector((state: RootState) => state.drugs);
  const user = useSelector((state: RootState) => state.login);
  const organizations = useSelector((state: RootState) => state.organizations);
  const LOAD_BUCKET_DELAY = 3000;
  const isAdmin = checkIsAdmin();
  const isChronoTech = checkIsChronoTech();
  const hasBucketUpdatePermission = useCheckPermissions(UserPermissions.BUCKETS_UPDATE);
  const [bucketLoading, setBucketLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const [toRefillIds, setToRefillIds] = useState<string[]>([]);
  const [openRefillModal, setOpenRefillModal] = useState<boolean>(false);
  const [exclusionsToManage, setExclusionsToManage] = useState<Order[]>([]);
  const [excludedDIN, setExcludedDIN] = useState<string | null>(null);
  const [importBucketList, setImportBucketList] = useState<boolean>(false);
  const [organizationView, setOrganizationView] = useState<string>('');
  const { t } = useTranslation();

  useEffect(() => {
    const waitSocket = async () => {
      const socket = await getSocketAsync();
      socket?.on('orders.intray.withDeactivatedDrugs', (messageExclusion) => {
        const inTrayWithExclusion = JSON.parse(messageExclusion);
        if (inTrayWithExclusion.orders[0].organizationId === user.organizationId) {
          setExclusionsToManage(inTrayWithExclusion.orders);
          setExcludedDIN(inTrayWithExclusion.DIN);
        }
      });

      return () => {
        socket?.off('orders.intray.withDeactivatedDrugs');
      };
    };

    waitSocket();
  }, [user.organizationId]);

  useEffect(() => {
    if (buckets) {
      setToRefillIds(buckets.filter((b) => b.toRefill).map((b) => b.id));
    }
  }, [buckets, setToRefillIds]);

  const handleLoadBucket = async () => {
    try {
      setBucketLoading(true);
      await loadBucket()(dispatch);
    } catch (err) {
      if (err instanceof HttpResponseError) {
        const content = await err.response?.json();
        setError(content?.error?.message || t('errorOccurred'));
        return;
      }
      console.error('ERROR LOADING BUCKET', err);
    } finally {
      setTimeout(() => {
        setBucketLoading(false);
      }, LOAD_BUCKET_DELAY);
    }
  };

  const handleClose = () => {
    setError(null);
  };

  const getPage = () => (
    <>
      <h2>{t('buckets')}</h2>
      <div
        className="buckets-btns"
        style={{
          float: 'right',
          marginBottom: 20,
          cursor: 'pointer',
        }}
      >
        {(isAdmin || isChronoTech) && (
          <select
            className="organization-view"
            name="organizationName"
            onChange={(e) => setOrganizationView(e.target.value)}
          >
            <option value="">Toutes</option>
            {organizations.map((o) => (
              <option value={o.id}>{o.name}</option>
            ))}
          </select>
        )}
        {(isAdmin || isChronoTech) && hasBucketUpdatePermission && (
          <Button
            variant="outline-secondary"
            style={{ marginRight: 15 }}
            onClick={() => setImportBucketList(true)}
          >
            <FontAwesomeIcon icon="file-import" />
          </Button>
        )}
        <Button
          onClick={() => setOpenRefillModal(true)}
          variant="secondary"
          style={{ marginRight: 15 }}
        >
          <FontAwesomeIcon icon="list" style={{ marginRight: 15 }} />
          <span>{t('toRefill')}</span>
          <span> ({toRefillIds.length})</span>
        </Button>
        <LoadingButton
          variant="success"
          onClick={() => handleLoadBucket()}
          loading={bucketLoading}
          disabled={bucketLoading}
        >
          {t('load')}
        </LoadingButton>
      </div>
      <ImportBucketListModal show={importBucketList} close={() => setImportBucketList(false)} />
      <Modal show={!!error} onHide={handleClose} backdrop="static" centered className="error-modal">
        <Modal.Header closeButton>
          <Modal.Title>{t('error')}</Modal.Title>
        </Modal.Header>
        <Modal.Body>{error}</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            {t('close')}
          </Button>
        </Modal.Footer>
      </Modal>
      <BucketsTable organizationView={organizationView} />
      <ExclusionInTrayModal
        bucketDIN={excludedDIN}
        orders={exclusionsToManage}
        close={() => {
          setExclusionsToManage([]);
          setExcludedDIN(null);
        }}
      />
      <RefillBucketsModal
        isOpen={openRefillModal}
        bucketsId={toRefillIds}
        editList={setToRefillIds}
        onClose={() => setOpenRefillModal(false)}
      />
    </>
  );

  return (
    <ResourcesHandler
      resources={[buckets, drugs, organizations]}
      resourceFetchers={[
        () => dispatch(fetchBuckets()),
        () => dispatch(fetchDrugs()),
        () => dispatch(fetchOrganizations()),
        () => dispatch(fetchUsers()),
      ]}
      getContents={getPage}
    />
  );
};

export default BucketsPage;

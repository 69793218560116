import React, { SyntheticEvent, useState, useRef } from 'react';
import { Dispatch } from 'redux';
import { useDispatch } from 'react-redux';
import Form from 'react-bootstrap/Form';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { login } from 'services/login/operations';
import { initializeSocket } from 'services/sockets';
import { LoadingButton } from 'components/shared/LoadingButton';
import links from 'utils/links';
import './login.scss';

const LoginPage = ({ history, location }: RouteComponentProps): JSX.Element => {
  const dispatch = useDispatch<Dispatch<any>>();
  const [loginError, setLoginError] = useState<any | undefined>(undefined);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const emailFieldRef = useRef<HTMLInputElement>(null);
  const passwordFieldRef = useRef<HTMLInputElement>(null);
  const { t } = useTranslation();

  const handleLogin = (e: SyntheticEvent) => {
    e.preventDefault();

    const regex = /^[a-zA-Z0-9_.@-]+$/;
    if (emailFieldRef?.current?.value && !regex.test(emailFieldRef.current.value)) {
      setLoginError(t('invalidUsername'));
      return;
    }
    setIsLoading(true);

    login(
      emailFieldRef?.current?.value,
      passwordFieldRef?.current?.value,
    )(dispatch).then(async (res) => {
      setIsLoading(false);
      if (res && res.payload && res.payload.error) {
        const jsonBody = await res.payload.error.response?.json();
        const { error } = jsonBody;
        setLoginError(t(error) || t('errorOccurred'));
        return;
      }

      if (passwordFieldRef && passwordFieldRef.current) {
        passwordFieldRef.current.value = '';
      }

      if (res && res.payload) {
        initializeSocket();
        history.push(location.state ? location.state.from : links.paths.home);
      }
    });
  };

  return (
    <div className="center">
      <form id="Login_form" onSubmit={handleLogin}>
        <Form.Group className="mb-3" controlId="emailAddress">
          <Form.Label>
            {t('userName')} <small>{`(${t('orEmailAddress')})`}</small>
          </Form.Label>
          <Form.Control
            type="text"
            autoFocus
            name="emailAddress"
            isInvalid={!!loginError}
            ref={emailFieldRef}
          />
          <Form.Control.Feedback type="invalid">{loginError}</Form.Control.Feedback>
        </Form.Group>
        <Form.Group className="mb-3" controlId="password">
          <Form.Label>{t('password')}</Form.Label>
          <Form.Control type="password" name="password" ref={passwordFieldRef} />
          <Form.Control.Feedback type="invalid" />
        </Form.Group>
        <LoadingButton loading={isLoading} variant="primary" type="submit">
          {t('login')}
        </LoadingButton>
      </form>
    </div>
  );
};

export default withRouter(LoginPage);

import { NewUser, User } from 'components/users/types';
import types from './types';

export type UserReducerState = User[];

export type UserAction =
  | { type: types.CREATE_USER_REQUEST; payload: { user: NewUser } }
  | { type: types.CREATE_USER_RESPONSE; payload: { user: User } }
  | { type: types.GET_USERS_REQUEST }
  | { type: types.GET_USERS_RESPONSE; payload: { users: User[] } }
  | {
      type: types.UPDATE_USER_REQUEST;
      payload: { id: string; user: User };
    }
  | { type: types.UPDATE_USER_RESPONSE; payload: { user: User } }
  | {
      type: types.PATCH_USER_REQUEST;
      payload: { id: string; user: Partial<User> };
    }
  | { type: types.PATCH_USER_RESPONSE; payload: { user: User } }
  | { type: types.DELETE_USER_REQUEST; payload: { userId: string } }
  | { type: types.DELETE_USER_RESPONSE; payload: { user: User } }
  | { type: types.TOGGLE_VASSYST_REQUEST; payload: { userId: string } }
  | { type: types.TOGGLE_VASSYST_RESPONSE; payload: { user: User } };

export default function reducers(
  state: UserReducerState = null!,
  action: UserAction,
): UserReducerState {
  switch (action.type) {
    case types.GET_USERS_RESPONSE: {
      return action.payload.users;
    }
    case types.CREATE_USER_RESPONSE:
      return (state || []).concat(action.payload.user);
    case types.UPDATE_USER_RESPONSE:
    case types.PATCH_USER_RESPONSE:
    case types.TOGGLE_VASSYST_RESPONSE:
      return state.map((user) => {
        if (user.id === action.payload.user.id) {
          return action.payload.user;
        }
        return user;
      });
    case types.DELETE_USER_RESPONSE:
      return state.filter((user) => user.id !== action.payload.user.id);
    default:
      return state;
  }
}

import { LoadingButton } from 'components/shared/LoadingButton';
import { FieldArray, Formik, getIn } from 'formik';
import React, { useEffect, useRef, useState } from 'react';
import * as yup from 'yup';
import { Alert, Button, Form, Modal } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { t } from 'i18next';
import { nextFocus } from 'components/buckets/formHelper';
import { useSelector } from 'react-redux';
import { processCubiOrders } from 'services/orders/endpoints';
import { RootState } from 'services/store';
import './style.scss';
import { ErrorResponse } from 'services/api';

interface AddCubiOrderModalProps {
  show: boolean;
  close: () => void;
}

interface FormValues {
  cubiOrdersId: string[];
}

const getInitialValues = (): FormValues => ({ cubiOrdersId: [''] });

interface FormSubmitProps {
  values: FormValues;
  setSubmitting: (isSubmitting: boolean) => void;
}

function AddCubiOrderModal({ show, close }: AddCubiOrderModalProps) {
  const user = useSelector((state: RootState) => state.login);
  const inputRef = useRef<HTMLInputElement | null>(null);
  const [HttpResponseError, setHttpResponseError] = useState<any>(null);
  const [formData, setFormData] = useState<FormValues>(getInitialValues);

  const noBarcodeData = (barcodes: string[]) => {
    const nbBarcode = barcodes.filter((b) => b !== '');
    return nbBarcode.length < 1;
  };

  const handleClose = () => {
    setFormData(getInitialValues);
    setHttpResponseError(null);
    close();
  };

  useEffect(() => {
    if (show && inputRef.current) {
      inputRef.current.focus();
    }
  }, [show]);

  const schema = yup.object().shape({
    cubiOrdersId: yup.array().of(
      yup
        .string()
        .length(8, t('barcodeMustBe8Char'))
        .matches(/^[0-9]+$/, t('fieldOnlyAcceptNumbers')),
    ),
  });

  const createCubiOrders = async ({ values, setSubmitting }: FormSubmitProps) => {
    setSubmitting(true);
    setHttpResponseError(null);

    const cubiOrdersId = values.cubiOrdersId.filter((id) => id !== '');

    try {
      await processCubiOrders(user.organizationId!, cubiOrdersId);
      handleClose();
    } catch (err) {
      const error = err as any;
      const body: ErrorResponse = await error.response.json();

      if (body.error.fields.cubiOrdersId) {
        setHttpResponseError(
          t('cubiBarcodeAlreadyInActiveOrder', {
            barcodeToLookupId: body.error.fields.cubiOrdersId,
          }),
        );
      } else if (body.error.fields.noOrderBarcode) {
        setHttpResponseError(
          t('barcodeYieldNoOrder', { barcode: body.error.fields.noOrderBarcode }),
        );
      } else {
        setHttpResponseError(t('errorAtUploadCubiOrders'));
      }
    }

    setSubmitting(false);
  };

  const handleChange = (id: string, cubiOrdersId: string[]) => {
    if (id.length === 8) {
      cubiOrdersId.push('');
      setTimeout(() => {
        nextFocus(`cubiOrdersId_${cubiOrdersId.length - 1}`);
      }, 0);
    }
  };

  return (
    <Modal show={show} onHide={close}>
      <Modal.Header>{t('scanCubiBarcode')}</Modal.Header>
      <Formik
        initialValues={formData}
        validationSchema={schema}
        onSubmit={(values, { setSubmitting }) => {
          createCubiOrders({ values, setSubmitting });
        }}
      >
        {({ values, errors, touched, handleBlur, handleSubmit, isSubmitting }) => (
          <Form onSubmit={handleSubmit}>
            <Modal.Body>
              {' '}
              <FieldArray
                name="cubiOrdersId"
                render={(arrayHelpers) => (
                  <div>
                    {values.cubiOrdersId.map((barcode, index) => (
                      <div className="barcode-group" key={index}>
                        <Form.Group>
                          <div>
                            <Form.Control
                              id={`cubiOrdersId_${index}`}
                              type="text"
                              ref={index === 0 ? inputRef : null}
                              name={`cubiOrdersId.${index}`}
                              value={barcode}
                              onBlur={(e) => {
                                handleBlur(e);
                                setFormData({ cubiOrdersId: values.cubiOrdersId });
                              }}
                              onChange={(e) => {
                                arrayHelpers.replace(index, e.target.value);
                                handleChange(e.target.value, values.cubiOrdersId);
                              }}
                              autoFocus={index === 0}
                              isInvalid={
                                getIn(touched, `cubiOrdersId.${index}`) &&
                                !!getIn(errors, `cubiOrdersId.${index}`)
                              }
                            />{' '}
                            <Button
                              variant="outline-secondary"
                              onClick={() => {
                                arrayHelpers.remove(index);
                                const updatedCubiOrdersId = [...values.cubiOrdersId];
                                updatedCubiOrdersId.splice(index, 1);
                                setFormData({ cubiOrdersId: updatedCubiOrdersId });
                              }}
                            >
                              <FontAwesomeIcon icon="minus" />
                            </Button>
                          </div>
                          <Form.Control.Feedback type="invalid">
                            {getIn(errors, `cubiOrdersId.${index}`)}
                          </Form.Control.Feedback>
                        </Form.Group>
                      </div>
                    ))}

                    <Button onClick={() => arrayHelpers.push('')}>
                      <FontAwesomeIcon icon="plus" />
                    </Button>
                  </div>
                )}
              />
              {HttpResponseError && (
                <Alert variant="danger" className="cubi-import-error">
                  <span className="error-message">
                    <strong style={{ whiteSpace: 'pre-wrap' }}>{HttpResponseError}</strong>
                  </span>
                </Alert>
              )}
            </Modal.Body>
            <Modal.Footer>
              <LoadingButton
                type="submit"
                disabled={isSubmitting || noBarcodeData(values.cubiOrdersId)}
              >
                {t('generateOrders')}
              </LoadingButton>
              <Button onClick={handleClose}>{t('close')}</Button>
            </Modal.Footer>
          </Form>
        )}
      </Formik>
    </Modal>
  );
}

export default AddCubiOrderModal;

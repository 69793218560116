import { get, post } from '../api';

interface LoginArgs {
  emailAddress?: string | null;
  password?: string | null;
}

const login = ({ emailAddress = null, password = null }: LoginArgs) =>
  post('/login', {}, { emailAddress, password });

const logout = () => post('/logout', {}, {});

const refreshToken = () => get('/status', {}, {});

export { login, logout, refreshToken };

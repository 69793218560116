export interface NewUser {
  organizationId: string;
  userName: string;
  password: string;
  emailAddress: string;
  firstName: string;
  lastName: string;
  role: UserRole;
}

export interface User extends Omit<NewUser, 'password' | 'role'> {
  id: string;
  createdAt: Date;
  permissions: UserPermissions[];
}

export interface UpdateUser
  extends Omit<User, 'id' | 'organizationId' | 'password' | 'permissions' | 'createdAt'> {
  role: UserRole;
}

export enum UserPermissions {
  BUCKETS_ACTIVATE = 'buckets.activate',
  BUCKETS_CREATE = 'buckets.create',
  BUCKETS_DEACTIVATE = 'buckets.deactivate',
  BUCKETS_DELETE = 'buckets.delete',
  BUCKETS_LIST = 'buckets.list',
  BUCKETS_LOAD = 'buckets.load',
  BUCKETS_UNLOAD = 'buckets.unload',
  BUCKETS_UPDATE = 'buckets.update',
  DRUGS_CREATE = 'drugs.create',
  DRUGS_DELETE = 'drugs.delete',
  DRUGS_LIST = 'drugs.list',
  DRUGS_UPDATE = 'drugs.update',
  MACHINES_CREATE = 'machines.create',
  MACHINES_DELETE = 'machines.delete',
  MACHINES_LIST = 'machines.list',
  MACHINES_UPDATE = 'machines.update',
  MACHINES_V_ASSYST = 'machine.v-assyst',
  ORDERS_LIST = 'orders.list',
  ORDERS_CREATE = 'orders.create',
  ORDERS_SEND = 'orders.send',
  ORDERS_UPDATE = 'orders.update',
  ORDERS_REPORTS = 'orders.reports',
  ORDERS_DELETE = 'orders.delete',
  ORGANIZATIONS_CREATE = 'organizations.create',
  ORGANIZATIONS_DASHBOARD = 'organizations.dashboard',
  ORGANIZATIONS_DELETE = 'organizations.delete',
  ORGANIZATIONS_LIST = 'organizations.list',
  ORGANIZATIONS_UPDATE = 'organizations.update',
  ORGANIZATIONS_SETTINGS = 'organizations.settings',
  USERS_CREATE = 'users.create',
  USERS_DELETE = 'users.delete',
  USERS_LIST = 'users.list',
  USERS_UPDATE = 'users.update',
}

export enum UserRole {
  ADMIN = 'admin',
  TECH = 'tech',
  SUPER_TECH = 'super_tech',
  CHRONO_TECH = 'chrono_tech',
  UNKNOWN = 'unknown',
}

export const AssignableUserRole: UserRole[] = [UserRole.TECH, UserRole.SUPER_TECH];

export const AdminAssignableUserRole: UserRole[] = [UserRole.ADMIN, UserRole.CHRONO_TECH];

export const TechPermissions: UserPermissions[] = [
  UserPermissions.BUCKETS_ACTIVATE,
  UserPermissions.BUCKETS_DEACTIVATE,
  UserPermissions.BUCKETS_LIST,
  UserPermissions.BUCKETS_LOAD,
  UserPermissions.BUCKETS_UNLOAD,
  UserPermissions.BUCKETS_UPDATE,
  UserPermissions.ORDERS_LIST,
  UserPermissions.ORDERS_SEND,
  UserPermissions.ORDERS_UPDATE,
  UserPermissions.DRUGS_CREATE,
  UserPermissions.DRUGS_LIST,
  UserPermissions.DRUGS_UPDATE,
  UserPermissions.MACHINES_LIST,
  UserPermissions.ORGANIZATIONS_LIST,
  UserPermissions.ORGANIZATIONS_SETTINGS,
  UserPermissions.USERS_LIST,
];

export const SuperTechPermissions: UserPermissions[] = [
  UserPermissions.BUCKETS_ACTIVATE,
  UserPermissions.BUCKETS_DEACTIVATE,
  UserPermissions.BUCKETS_LIST,
  UserPermissions.BUCKETS_LOAD,
  UserPermissions.BUCKETS_UNLOAD,
  UserPermissions.BUCKETS_UPDATE,
  UserPermissions.ORDERS_LIST,
  UserPermissions.ORDERS_SEND,
  UserPermissions.ORDERS_UPDATE,
  UserPermissions.DRUGS_CREATE,
  UserPermissions.DRUGS_LIST,
  UserPermissions.DRUGS_UPDATE,
  UserPermissions.MACHINES_LIST,
  UserPermissions.ORGANIZATIONS_LIST,
  UserPermissions.ORGANIZATIONS_SETTINGS,
  UserPermissions.USERS_CREATE,
  UserPermissions.USERS_DELETE,
  UserPermissions.USERS_LIST,
  UserPermissions.USERS_UPDATE,
];

export const ChronoTechPermissions: UserPermissions[] = [
  UserPermissions.BUCKETS_ACTIVATE,
  UserPermissions.BUCKETS_CREATE,
  UserPermissions.BUCKETS_DEACTIVATE,
  UserPermissions.BUCKETS_DELETE,
  UserPermissions.BUCKETS_LIST,
  UserPermissions.BUCKETS_LOAD,
  UserPermissions.BUCKETS_UNLOAD,
  UserPermissions.BUCKETS_UPDATE,
  UserPermissions.DRUGS_CREATE,
  UserPermissions.DRUGS_DELETE,
  UserPermissions.DRUGS_LIST,
  UserPermissions.DRUGS_UPDATE,
  UserPermissions.MACHINES_CREATE,
  UserPermissions.MACHINES_LIST,
  UserPermissions.MACHINES_UPDATE,
  UserPermissions.ORDERS_DELETE,
  UserPermissions.ORDERS_LIST,
  UserPermissions.ORDERS_SEND,
  UserPermissions.ORDERS_UPDATE,
  UserPermissions.ORDERS_REPORTS,
  UserPermissions.ORGANIZATIONS_UPDATE,
  UserPermissions.ORGANIZATIONS_DASHBOARD,
  UserPermissions.ORGANIZATIONS_LIST,
  UserPermissions.ORGANIZATIONS_SETTINGS,
  UserPermissions.USERS_CREATE,
  UserPermissions.USERS_DELETE,
  UserPermissions.USERS_LIST,
  UserPermissions.USERS_UPDATE,
];

export const AdminPermissions: UserPermissions[] = [
  UserPermissions.BUCKETS_ACTIVATE,
  UserPermissions.BUCKETS_CREATE,
  UserPermissions.BUCKETS_DEACTIVATE,
  UserPermissions.BUCKETS_DELETE,
  UserPermissions.BUCKETS_LIST,
  UserPermissions.BUCKETS_LOAD,
  UserPermissions.BUCKETS_UNLOAD,
  UserPermissions.BUCKETS_UPDATE,
  UserPermissions.DRUGS_CREATE,
  UserPermissions.DRUGS_DELETE,
  UserPermissions.DRUGS_LIST,
  UserPermissions.DRUGS_UPDATE,
  UserPermissions.MACHINES_CREATE,
  UserPermissions.MACHINES_DELETE,
  UserPermissions.MACHINES_LIST,
  UserPermissions.MACHINES_UPDATE,
  UserPermissions.ORDERS_DELETE,
  UserPermissions.ORDERS_LIST,
  UserPermissions.ORDERS_SEND,
  UserPermissions.ORDERS_UPDATE,
  UserPermissions.ORDERS_REPORTS,
  UserPermissions.ORGANIZATIONS_CREATE,
  UserPermissions.ORGANIZATIONS_UPDATE,
  UserPermissions.ORGANIZATIONS_DASHBOARD,
  UserPermissions.ORGANIZATIONS_DELETE,
  UserPermissions.ORGANIZATIONS_LIST,
  UserPermissions.ORGANIZATIONS_SETTINGS,
  UserPermissions.USERS_CREATE,
  UserPermissions.USERS_DELETE,
  UserPermissions.USERS_LIST,
  UserPermissions.USERS_UPDATE,
];

import { loginRequest, loginResponse, logoutRequest, logoutResponse } from './actions';
import {
  login as APIlogin,
  logout as APIlogout,
  refreshToken as APIRefreshToken,
} from './endpoints';

export function login(emailAddress: string | null = null, password: string | null = null) {
  return (dispatch): Promise<ReturnType<typeof loginResponse>> => {
    dispatch(loginRequest({ emailAddress, password }));
    return APIlogin({ emailAddress, password })
      .then((res) => dispatch(loginResponse(res)))
      .catch((err) => dispatch(loginResponse({ error: err })));
  };
}

export function refreshToken() {
  return APIRefreshToken();
}

export function logout() {
  return (dispatch): Promise<ReturnType<typeof logoutResponse>> => {
    dispatch(logoutRequest());
    return APIlogout().then((res) => dispatch(logoutResponse(res)));
  };
}

const operations = {
  login,
  logout,
  refreshToken,
};

export default operations;

import React from 'react';
import { useDispatch } from 'react-redux';
import Form from 'react-bootstrap/Form';
import { useTranslation } from 'react-i18next';
import { ErrorMessage, Formik, FormikHelpers } from 'formik';
import Modal from 'components/UI/Modal';
import { deactivateBucket } from 'services/buckets/operations';
import { focusInput } from 'utils/forms';
import { Drug } from 'components/drugs/types';
import { handleHttpResponseError } from 'components/shared/helpers';
import { Bucket, DeactivatedReason } from './types';

interface FormValues {
  deactivatedReason: DeactivatedReason;
}

const initialValues: FormValues = {
  deactivatedReason: DeactivatedReason.BACK_ORDER,
};

interface DeactivateBucketModalProps {
  isOpen: boolean;
  bucket: Bucket;
  drug: Drug | undefined;
  onClose: () => void;
}

function DeactivateBucketModal({ isOpen, bucket, drug, onClose }: DeactivateBucketModalProps) {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const handleSubmit = async (
    values: FormValues,
    { setSubmitting, setFieldError }: FormikHelpers<FormValues>,
  ): Promise<any> => {
    try {
      await deactivateBucket(bucket.id, values.deactivatedReason)(dispatch);
      setSubmitting(false);
      onClose();
    } catch (err) {
      handleHttpResponseError(err, 'FAILED TO DEACTIVATE BUCKET', setFieldError);
    }
  };
  return (
    <Formik initialValues={initialValues} onSubmit={handleSubmit}>
      {({ errors, touched, handleSubmit, isSubmitting, setFieldValue }) => (
        <Modal
          show={isOpen}
          onHide={onClose}
          modalClass="deactivate-bucket-modal"
          onSubmit={handleSubmit}
          isSubmitting={isSubmitting}
          disabledSubmitButton={false}
          title={t('deactivateBucket', {
            position: bucket.position,
            DIN: bucket.DIN,
            drugName: drug ? drug.name.toUpperCase() : '-',
          })}
        >
          <div
            style={{
              textAlign: 'left',
            }}
          >
            <Form
              id="DeactivateBucket_form"
              noValidate
              onSubmit={(e) => {
                if (Object.keys(errors).length > 0) {
                  focusInput(Object.keys(errors)[0]);
                }
                handleSubmit(e);
              }}
            >
              <Form.Group>
                <Form.Label>{t('reason')}</Form.Label>
                <select
                  name="deactivatedReason"
                  defaultValue={DeactivatedReason.BACK_ORDER}
                  onChange={(e) =>
                    setFieldValue('deactivatedReason', e.target.value as DeactivatedReason)
                  }
                  className={
                    errors.deactivatedReason && touched.deactivatedReason ? 'error-select' : ''
                  }
                >
                  {Object.values(DeactivatedReason)
                    .filter((reason) => reason !== DeactivatedReason.EXPIRED_DRUGS)
                    .map((reason) => (
                      <option key={reason} value={reason}>
                        {t(reason)}
                      </option>
                    ))}
                </select>
                <ErrorMessage
                  name="deactivatedReason"
                  render={(msg) => <span className="error-message">{t(msg)}</span>}
                />
              </Form.Group>
            </Form>
          </div>
        </Modal>
      )}
    </Formik>
  );
}

export default DeactivateBucketModal;

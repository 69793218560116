import { NewUser, User } from 'components/users/types';
import { UserAction } from './reducers';
import types from './types';

export const createUserRequest = (user: NewUser): UserAction => ({
  type: types.CREATE_USER_REQUEST,
  payload: { user },
});

export const createUserResponse = (user: User): UserAction => ({
  type: types.CREATE_USER_RESPONSE,
  payload: { user },
});

export const getUsersRequest = (): UserAction => ({
  type: types.GET_USERS_REQUEST,
});

export const getUsersResponse = (payload: { users: User[] }): UserAction => ({
  type: types.GET_USERS_RESPONSE,
  payload,
});

export const updateUserRequest = (id: string, user: User): UserAction => ({
  type: types.UPDATE_USER_REQUEST,
  payload: { id, user },
});

export const updateUserResponse = (user: User): UserAction => ({
  type: types.UPDATE_USER_RESPONSE,
  payload: { user },
});

export const patchUserRequest = (id: string, user: Partial<User>): UserAction => ({
  type: types.PATCH_USER_REQUEST,
  payload: { id, user },
});

export const patchUserResponse = (user: User): UserAction => ({
  type: types.PATCH_USER_RESPONSE,
  payload: { user },
});

export const deleteUserRequest = (userId: string): UserAction => ({
  type: types.DELETE_USER_REQUEST,
  payload: { userId },
});

export const deleteUserResponse = (user: User): UserAction => ({
  type: types.DELETE_USER_RESPONSE,
  payload: { user },
});

export const toggleVAssystRequest = (userId: string): UserAction => ({
  type: types.TOGGLE_VASSYST_REQUEST,
  payload: { userId },
});

export const toggleVAssystResponse = (user: User): UserAction => ({
  type: types.TOGGLE_VASSYST_RESPONSE,
  payload: { user },
});

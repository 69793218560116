import React, { useState, useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import Table from 'react-bootstrap/Table';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronUp, faChevronDown } from '@fortawesome/free-solid-svg-icons';
import { useTranslation } from 'react-i18next';
import { RootState } from 'services/store';
import BucketRow from './BucketRow';
import { Bucket, DeactivatedReason } from './types';
import './style.scss';
import ExpiredDrugsModal from './ExpiredDrugsModal/ExpiredDrugsModal';
import SingleRefillModal from './RefillModals/SingleRefillModal';

interface BucketsTableProps {
  organizationView: string;
}

const BucketsTable = ({ organizationView }: BucketsTableProps): JSX.Element => {
  const buckets = useSelector((state: RootState) => state.buckets);
  const drugs = useSelector((state: RootState) => state.drugs);
  const [search, setSearch] = useState<string>('');
  const [invalidSearch, setInvalidSearch] = useState<boolean>(false);
  const [showExpiredModal, setShowExpiredModal] = useState<string>('');
  const [showRefillModal, setShowRefillModal] = useState<Bucket | null>(null);
  const [sortOrder, setSortOrder] = useState<'asc' | 'desc'>(
    (localStorage.getItem('sortOrder') as 'asc' | 'desc') ?? 'asc',
  );
  const [onFocus, setOnFocus] = useState<string>(localStorage.getItem('onFocus') ?? '#');

  const [error, setError] = useState<string | null>(null);
  const { t } = useTranslation();

  useEffect(() => {
    localStorage.setItem('sortOrder', sortOrder);
    localStorage.setItem('onFocus', onFocus);
  }, [sortOrder, onFocus]);

  const sortedBuckets = useMemo(
    () =>
      [...buckets].sort((a, b) => {
        if (onFocus === 'quantity') {
          if (sortOrder === 'asc') {
            return a.quantity - b.quantity;
          }
          return b.quantity - a.quantity;
        }
        if (sortOrder === 'asc') {
          return parseInt(a.position, 10) < parseInt(b.position, 10) ? -1 : 1;
        }
        return parseInt(a.position, 10) > parseInt(b.position, 10) ? -1 : 1;
      }),
    [buckets, onFocus, sortOrder],
  );

  const handleChangeSearch = (e): void => {
    setInvalidSearch(false);
    const regex = /^[a-zA-Z0-9 .]*$/;
    if (!regex.test(e.target.value)) {
      setInvalidSearch(true);
    } else {
      setSearch(e.target.value);
    }
  };
  const handleOpenEditionModal = (bucket: Bucket) => {
    const thisMonth = new Date().toISOString().slice(0, 7);
    const hasExpiredDrugs =
      bucket.nearestExpirationDate && bucket.nearestExpirationDate <= thisMonth;
    if (
      hasExpiredDrugs ||
      (bucket.isDeactivated && bucket.deactivatedReason === DeactivatedReason.EXPIRED_DRUGS)
    ) {
      setShowExpiredModal(bucket.id);
    } else {
      setShowRefillModal(bucket);
    }
  };

  const handleSort = (type: string) => {
    switch (type) {
      case '#':
        if (onFocus !== '#') {
          setOnFocus('#');
        } else {
          setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
        }
        break;
      case 'quantity':
        if (onFocus !== 'quantity') {
          setOnFocus('quantity');
        } else {
          setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
        }
        break;
      default:
        break;
    }
  };

  const handleCloseEditionModal = () => {
    setShowExpiredModal('');
    setShowRefillModal(null);
  };

  const handleClose = () => {
    setError(null);
  };
  const rows =
    sortedBuckets &&
    sortedBuckets
      .filter((bucket) => {
        if (organizationView && bucket.organizationId !== organizationView) {
          return false;
        }

        const drug = drugs.find((d) => d.DIN === bucket.DIN);
        if (!drug) return false;

        const searchQuery = search?.trim();

        // eslint-disable-next-line no-restricted-globals
        if (!isNaN(parseInt(searchQuery, 10))) {
          if (searchQuery.length === 8) {
            return bucket.DIN.includes(searchQuery);
          }

          if ((searchQuery.length === 12 || searchQuery.length === 13) && bucket.UPCs) {
            return bucket.UPCs.some((upc) => upc.includes(searchQuery));
          }
        }

        return drug.name.toLowerCase().includes(searchQuery.toLowerCase());
      })

      .map((bucket) => (
        <BucketRow
          key={bucket.id}
          bucket={bucket}
          onEnterBucket={() => handleOpenEditionModal(bucket)}
          onUnloadingError={setError}
        />
      ));

  return (
    <>
      {showRefillModal && (
        <SingleRefillModal
          isOpen={!!showRefillModal}
          onClose={() => setShowRefillModal(null)}
          bucket={showRefillModal}
        />
      )}
      <ExpiredDrugsModal
        isOpen={!!showExpiredModal}
        bucketsId={[showExpiredModal]}
        onClose={handleCloseEditionModal}
      />
      <div>
        <div style={{ position: 'relative', width: '63%' }}>
          <Form.Control
            type="text"
            onChange={handleChangeSearch}
            placeholder={`${t('search')}...`}
            isInvalid={invalidSearch}
          />
          <Form.Control.Feedback type="invalid">{t('invalidSearchQuery')}</Form.Control.Feedback>
          <FontAwesomeIcon
            icon="search"
            color="black"
            style={{
              background: 'transparent',
              border: 'none',
              cursor: 'pointer',
              display: 'inline-block',
              fontSize: 20,
              position: 'absolute',
              top: 10,
              right: 12,
              zIndex: 2,
            }}
          />
        </div>
      </div>
      <br />
      <Table responsive bordered striped>
        <thead>
          <tr>
            <th style={{ width: 50 }}>
              <Button variant="link" onClick={() => handleSort('#')} className="sorting">
                #
                {onFocus === '#' &&
                  (sortOrder === 'asc' ? (
                    <FontAwesomeIcon icon={faChevronUp} />
                  ) : (
                    <FontAwesomeIcon icon={faChevronDown} />
                  ))}
              </Button>
            </th>
            <th>{t('DIN')}</th>
            <th>{t('drug')}</th>
            <th>{t('strength')}</th>
            <th>{t('format')}</th>
            <th style={{ width: 100 }}>{t('qtymonth')}</th>
            <th style={{ width: 100 }}>
              <Button variant="link" onClick={() => handleSort('quantity')} className="sorting">
                {t('quantity')}{' '}
                {onFocus === 'quantity' &&
                  (sortOrder === 'asc' ? (
                    <FontAwesomeIcon icon={faChevronUp} />
                  ) : (
                    <FontAwesomeIcon icon={faChevronDown} />
                  ))}
              </Button>
            </th>
            <th style={{ width: 200 }} />
          </tr>
        </thead>
        <tbody>{rows}</tbody>
      </Table>
      <Modal show={!!error} onHide={handleClose} backdrop="static" centered className="error-modal">
        <Modal.Header closeButton>
          <Modal.Title>{t('fillingRequired')}</Modal.Title>
        </Modal.Header>
        <Modal.Body>{error}</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            {t('close')}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default BucketsTable;

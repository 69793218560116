/* eslint-disable */
import { t } from 'i18next';
import React, { useEffect, useRef, useState } from 'react';
import { Button } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { toggleManuallyDistributedDrug } from 'services/orders/endpoints';
import { RootState } from 'services/store';
import {
  DrugDistribution,
  DrugOrder,
  DrugOrderExcludedReason,
  DrugOrderStatus,
  OrderStatus,
} from 'components/orders/types';
import { emptyCardMap, parseBarcode } from 'components/orders/helpers';
import './style.scss';
import BlisterCardRow from './BlisterCardRow';

interface BlisterCardProps {
  orderId: string;
  drugOrder: DrugOrder;
  barcodes: string[];
  status: OrderStatus;
  validUPC: boolean;
}

const BlisterCard = ({ orderId, drugOrder, barcodes, status, validUPC }: BlisterCardProps) => {
  const parentRef = useRef<HTMLDivElement>(null);
  const blisterCardsRef = useRef<HTMLDivElement>(null);
  const [top, setTop] = useState<string>('0px');
  const drugs = useSelector((state: RootState) => state.drugs);
  const { DIN } = drugOrder;
  const drug = drugs.find((d) => d.DIN === DIN);
  const blisterCards: DrugDistribution[] = barcodes.map((barcode) => {
    const distribution = drugOrder.distributions.find(
      (distribution) => distribution?.barcode === barcode,
    );
    if (distribution) {
      return distribution;
    }
    return {
      barcode,
      cardMap: emptyCardMap(),
    };
  });

  useEffect(() => {
    const handleScroll = () => {
      if (parentRef.current && blisterCardsRef.current) {
        const parentRect = parentRef.current.getBoundingClientRect();
        const parentHeight = parentRef.current.offsetHeight;
        const blisterCardHeight = blisterCardsRef.current.offsetHeight;

        if (parentRect.bottom - blisterCardHeight < 0) {
          setTop(`${Math.max(0, parentHeight - blisterCardHeight)}px`);
        } else if (parentRect.top >= 0) {
          setTop('0px');
        } else if (parentRect.top < 0) {
          setTop(`${-parentRect.top}px`);
        }
      }
    };

    window.addEventListener('scroll', handleScroll);
    handleScroll();

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const emptyDistribution: DrugDistribution = {
    barcode: '',
    cardMap: emptyCardMap(),
  };

  const conflictDistributionWarning = () => (
    <div style={{ textAlign: 'center', margin: '3% auto', maxWidth: '75%' }}>
      <strong>{t('conflictualDistribution')}</strong>
    </div>
  );

  const drugName = drug ? drug.name.toUpperCase() : `DIN ${drugOrder.DIN}`;
  const drugForce = drug && drug.force ? drug.force.toUpperCase() : '';

  return (
    <div className="blister-card-container" ref={parentRef}>
      <div className="blister-cards" ref={blisterCardsRef} style={{ top }}>
        <div className="full-card">
          {blisterCards.length === 1 && (
            <>
              <div className="left-card">
                <div className="blister-card-id">
                  <strong>{barcodes[0]}</strong>
                </div>
                {blisterCards[0].excludedReason === DrugOrderExcludedReason.DUPLICATE_DIN
                  ? conflictDistributionWarning()
                  : Array.from({ length: 7 }, (_, i) => (
                      <BlisterCardRow
                        key={`week-${i}-${DIN}-${blisterCards[0].barcode}`}
                        DIN={DIN}
                        distribution={blisterCards[0]}
                        day={i}
                      />
                    ))}
              </div>
              <div className="right-card">
                <div className="blister-card-id">
                  <strong>{t('unassigned')}</strong>
                </div>
                {Array.from({ length: 7 }, (_, i) => (
                  <BlisterCardRow
                    key="empty-blister-1st-tray"
                    DIN={DIN}
                    distribution={emptyDistribution}
                    day={i}
                  />
                ))}
              </div>
            </>
          )}
          {blisterCards.length > 1 &&
            blisterCards.slice(0, 2).map((blisterCard, index) => (
              <div className={index === 0 ? 'left-card' : 'right-card'}>
                <div className="blister-card-id">
                  <strong>{parseBarcode(barcodes[index])}</strong>
                </div>
                {blisterCard.excludedReason === DrugOrderExcludedReason.DUPLICATE_DIN
                  ? conflictDistributionWarning()
                  : Array.from({ length: 7 }, (_, i) => (
                      <BlisterCardRow
                        key={`week-${i}-${DIN}-${blisterCard.barcode}`}
                        DIN={DIN}
                        distribution={blisterCard}
                        day={i}
                      />
                    ))}
              </div>
            ))}
        </div>
        {blisterCards.length > 2 && (
          <div className="full-card">
            {blisterCards.slice(2).map((blisterCard, index) => (
              <div className={index === 0 ? 'left-card' : 'right-card'}>
                <div className="blister-card-id">
                  <strong>{parseBarcode(barcodes[index + 2])}</strong>
                </div>
                {blisterCard.excludedReason === DrugOrderExcludedReason.DUPLICATE_DIN
                  ? conflictDistributionWarning()
                  : Array.from({ length: 7 }, (_, i) => (
                      <BlisterCardRow
                        key={`week-${i}-${DIN}-${blisterCard.barcode}`}
                        DIN={DIN}
                        distribution={blisterCard}
                        day={i}
                      />
                    ))}
              </div>
            ))}

            {blisterCards.length === 3 && (
              <div className="right-card">
                <div className="blister-card-id">
                  <strong>{t('unassigned')}</strong>
                </div>
                {Array.from({ length: 7 }, (_, i) => (
                  <BlisterCardRow
                    key="empty-blister-2nd-tray"
                    DIN={DIN}
                    distribution={emptyDistribution}
                    day={i}
                  />
                ))}
              </div>
            )}
          </div>
        )}
        {drugOrder.distributions.some((distribution) => distribution?.isExcluded) && (
          <Button
            disabled={
              !drugOrder.distributions.some(
                (distribution) =>
                  distribution.excludedReason === DrugOrderExcludedReason.DIN_UNAVAILABLE,
              ) && !validUPC
            }
            /* BYPASS disabled={!!drugOrder.isFlagged || status !== OrderStatus.PENDING} */
            className="complete-distribution"
            onClick={() => toggleManuallyDistributedDrug(orderId, DIN)}
          >
            {drugOrder.distributions.some(
              (distribution) => distribution?.status === DrugOrderStatus.DISTRIBUTED,
            )
              ? t('cancelDistributionOf', {
                  drug: drugName, // eslint-disable-line indent
                  force: drugForce, // eslint-disable-line indent
                }) // eslint-disable-line indent
              : t('completeDistributionOf', { drug: drugName, force: drugForce })}
          </Button>
        )}
      </div>
    </div>
  );
};

export default BlisterCard;

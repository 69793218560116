import React, { useState } from 'react';
import { t } from 'i18next';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Modal from 'components/UI/Modal';
import { Formik, FormikHelpers } from 'formik';
import * as yup from 'yup';
import { getTrayOrder, patchOrder, removeTray } from 'services/orders/endpoints';
import { focusInput } from 'utils/forms';
import { Alert } from 'react-bootstrap';
import { DateTime } from 'luxon';
import { handleHttpResponseError } from 'components/shared/helpers';
import { NewestOrder, Order, OrderStatus } from '../../types';
import AlreadyLinkedAlert from './AlreadyLinkedAlert';

interface FormValues {
  trayId?: string;
  secondTrayId?: string;
  newestOrder?: NewestOrder;
}

const initialValues: FormValues = {
  trayId: undefined,
  secondTrayId: undefined,
  newestOrder: undefined,
};

interface LinkToTrayModalProps {
  close: () => void;
  order: Order | null;
  show: boolean;
}

function LinkToTrayModal({ order, show, close }: LinkToTrayModalProps) {
  const [inFirstTray, setInFirstTray] = useState<{
    order: Order;
    trayId: string;
  } | null>(null);

  const [inSecondTray, setInSecondTray] = useState<{
    order: Order;
    trayId: string;
  } | null>(null);
  const [byPassNewest, setByPassNewest] = useState<boolean>(false);
  const twoWeeksInMilliseconds = 14 * 24 * 60 * 60 * 1000;
  const twoWeeksPriorToday = new Date(Date.now() - twoWeeksInMilliseconds).toISOString();
  const orderThreeCards = order!.nbCards === 3;
  const orderFourCards = order!.nbCards === 4;

  const schema = yup.object().shape({
    trayId: yup
      .string()
      .matches(/^[0-9]+$/, t('fieldOnlyAcceptNumbers'))
      .required(),
    secondTrayId: yup.string().matches(/^[0-9]+$/, t('fieldOnlyAcceptNumbers')),
  });

  const unlinkTray = async (toUnlink: Order, trayId: string) => {
    if (toUnlink.trayIds!.length > 1) {
      await patchOrder(toUnlink.id, { nbCards: 2 });
    } else {
      await patchOrder(toUnlink.id, { status: OrderStatus.PENDING });
    }
    await removeTray(toUnlink.id, trayId);

    if (inFirstTray?.order.id === toUnlink.id) {
      setInFirstTray(null);
    } else {
      setInSecondTray(null);
    }
  };

  const handleClose = () => {
    setInFirstTray(null);
    setInSecondTray(null);
    setByPassNewest(false);
    close();
  };

  const handleLinking = async (values: FormValues, setSubmitting, setFieldError) => {
    const patchInfos: Partial<Order> = {
      trayIds:
        orderThreeCards || orderFourCards
          ? [values.trayId!, values.secondTrayId!]
          : [values.trayId!],
      newestOrder: byPassNewest ? { ...order!.newestOrder!, byPass: true } : undefined,
      status: OrderStatus.INTRAY,
    };

    try {
      await patchOrder(order!.id, patchInfos);
      setSubmitting(false);
      handleClose();
    } catch (err) {
      handleHttpResponseError(err, 'FAILED TO LINK TRAY', setFieldError);
    }
  };

  const handleSubmit = async (
    values: FormValues,
    { setSubmitting, setFieldError }: FormikHelpers<FormValues>,
  ): Promise<any> => {
    setSubmitting(true);

    const firstTray = await getTrayOrder(values.trayId!).then((res) => res.order);
    if (firstTray) {
      setInFirstTray({ order: firstTray, trayId: values.trayId! });
    }
    let secondTray: Order | null = null;
    if ((orderThreeCards || orderFourCards) && values.secondTrayId) {
      secondTray = await getTrayOrder(values.secondTrayId).then((res) => res.order);
      if (secondTray) {
        setInSecondTray({ order: secondTray, trayId: values.secondTrayId });
      }
    }

    if (order?.id && !firstTray && !secondTray && (!order.newestOrder || byPassNewest)) {
      await handleLinking(values, setSubmitting, setFieldError);
    }

    setSubmitting(false);
  };

  return order?.id ? (
    <Formik initialValues={initialValues} validationSchema={schema} onSubmit={handleSubmit}>
      {({ values, errors, handleChange, handleSubmit, isSubmitting }) => (
        <Modal
          show={show}
          modalClass="linkToTrayModal"
          disabledSubmitButton={!(!order?.newestOrder || order?.newestOrder.byPass || byPassNewest)}
          onHide={() => {
            handleClose();
          }}
          onSubmit={handleSubmit}
          isSubmitting={isSubmitting}
          title={t('linkOrderToTray')}
        >
          <div
            style={{
              textAlign: 'left',
            }}
          >
            <p>
              {t('linkingWithOrder', {
                lookupId: order.lookupId,
                patient: order.patient,
                createdAt: DateTime.fromISO(order.createdAt).toFormat('yyyy-MM-dd'),
              })}
            </p>
            <Form
              id="LinkToTray_form"
              noValidate
              onSubmit={(e) => {
                if (Object.keys(errors).length > 0) {
                  focusInput(Object.keys(errors)[0]);
                }
                handleSubmit(e);
              }}
            >
              <Form.Group className="mb-3" controlId="trayId">
                <Form.Label>{t('trayNumber')}</Form.Label>
                <Form.Control
                  type="text"
                  name="trayId"
                  value={values.trayId}
                  onChange={(e) => {
                    handleChange(e);
                  }}
                  isInvalid={!!errors.trayId}
                />
                <Form.Control.Feedback type="invalid">{errors.trayId}</Form.Control.Feedback>

                {inFirstTray && (
                  <AlreadyLinkedAlert
                    linkedOrder={inFirstTray.order}
                    nextAction={() => unlinkTray(inFirstTray.order, values.trayId!)}
                  />
                )}
              </Form.Group>
              {(orderThreeCards || orderFourCards) && (
                <Form.Group className="mb-3" controlId="secondTrayId">
                  {orderThreeCards && <Form.Label>{t('halfTrayNumber')}</Form.Label>}
                  <Form.Control
                    type="text"
                    value={values.secondTrayId}
                    onChange={handleChange}
                    isInvalid={!!errors.secondTrayId}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.secondTrayId}
                  </Form.Control.Feedback>

                  {inSecondTray && (
                    <AlreadyLinkedAlert
                      linkedOrder={inSecondTray.order}
                      nextAction={() => unlinkTray(inSecondTray.order, values.secondTrayId!)}
                    />
                  )}
                </Form.Group>
              )}

              {order!.createdAt < twoWeeksPriorToday && (
                <Alert variant="warning">{t('warning2weeks')}</Alert>
              )}
              {!!order!.newestOrder && !order?.newestOrder.byPass && (
                <Alert variant={byPassNewest ? 'secondary' : 'warning'}>
                  {!byPassNewest && !order?.newestOrder.byPass && (
                    <span>
                      <p>
                        {t('linkingWithOrder', {
                          lookupId: order?.lookupId,
                          patient: order?.patient,
                          createdAt: DateTime.fromISO(order!.createdAt).toFormat('yyyy-MM-dd'),
                        })}
                      </p>
                      <p>
                        {t('newestOrderExist', {
                          lookupId: order!.newestOrder.lookupId,
                          patient: order!.patient,
                          createdAt: DateTime.fromISO(order!.newestOrder.createdAt).toFormat(
                            'yyyy-MM-dd',
                          ),
                        })}
                      </p>
                      <hr />
                      <p className="validation-association-older-order">
                        {t('continueWith', {
                          lookupId: order?.lookupId,
                        })}
                        <Button variant="warning" onClick={() => setByPassNewest(true)}>
                          {t('yes')}
                        </Button>
                      </p>
                    </span>
                  )}
                  {byPassNewest && (
                    <span>
                      {' '}
                      {t('willLinkTrayWithOrder', {
                        lookupId: order?.lookupId,
                      })}
                    </span>
                  )}
                </Alert>
              )}
            </Form>
          </div>
        </Modal>
      )}
    </Formik>
  ) : (
    <div />
  );
}

export default LinkToTrayModal;

import React, { useEffect, useState } from 'react';
import './style.scss';
import { Dispatch } from 'redux';
import { useSelector, useDispatch } from 'react-redux';
import Alert from 'react-bootstrap/Alert';
import Button from 'react-bootstrap/Button';
import { Collapse, Modal } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { inRefillList, unloadBucket } from 'services/buckets/operations';
import { HttpResponseError } from 'services/api';
import { RootState } from 'services/store';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Bucket, DeactivatedReason } from '../types';
import BucketRefillRow from './BucketRefillRow';
import BucketBORow from './BucketBORow';
import SingleRefillModal from './SingleRefillModal';

interface BucketModalProps {
  isOpen: boolean;
  bucketsId: string[];
  editList: (list: string[]) => void;
  onClose: () => void;
}

function RefillBucketsModal({ isOpen, bucketsId, editList, onClose }: BucketModalProps) {
  const dispatch = useDispatch<Dispatch<any>>();
  const { t } = useTranslation();
  const [bucketUnloading, setBucketUnloading] = useState<string | null>(null);
  const [refillBucketId, setRefillBucketId] = useState<string>('');
  const [refilledBucketsIds, setRefilledBucketsIds] = useState<string[]>([]);
  const [error, setError] = useState<string | null>(null);
  const [buckets, setBuckets] = useState<Bucket[]>([]);
  const [BOBuckets, setBOBuckets] = useState<Bucket[]>([]);
  const [showBO, setShowBO] = useState<boolean>(false);
  const drugs = useSelector((state: RootState) => state.drugs);
  const allBuckets = useSelector((state: RootState) => state.buckets);
  const isSingleBucket = buckets.length === 1 && BOBuckets.length === 0;

  useEffect(() => {
    if (allBuckets) {
      setBuckets(allBuckets.filter((b) => bucketsId.includes(b.id)));
      setBOBuckets(
        allBuckets.filter(
          (b) =>
            b.deactivatedReason === DeactivatedReason.BACK_ORDER ||
            b.deactivatedReason === DeactivatedReason.ORDERED,
        ),
      );
    }
  }, [isOpen, bucketsId, allBuckets]);

  const removeFromList = async (bucket: Bucket) => {
    if (bucket.toRefill) {
      await inRefillList(bucket.id, false)(dispatch);
    }
    editList(bucketsId.filter((id) => id !== bucket.id));
  };

  const refillComplete = (bucket: Bucket) => {
    setRefillBucketId('');
    setRefilledBucketsIds([...refilledBucketsIds, bucket.id]);
  };

  const startUnloading = async (bucketId: string) => {
    try {
      setBucketUnloading(bucketId);
      await unloadBucket(bucketId)(dispatch);
      setBucketUnloading(null);
    } catch (err) {
      setBucketUnloading(null);
      if (err instanceof HttpResponseError) {
        const content = await err.response?.json();
        setError(content?.error?.message || t('errorOccurred'));
        return;
      }

      console.error('ERROR UNLOADING BUCKET', err);
    }
  };

  const startRefill = async (bucketId: string) => {
    setRefillBucketId(bucketId);
    startUnloading(bucketId);
  };

  if (isSingleBucket) {
    return <SingleRefillModal isOpen={isOpen} bucket={buckets[0]} onClose={onClose} />;
  }

  return (
    <Modal show={isOpen} onHide={onClose} className="refill-buckets-modal">
      <Modal.Header closeButton>
        <Modal.Title>{t('refill')}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {!!error && <Alert>{t('error')}</Alert>}
        {!isSingleBucket &&
          buckets.map((bucket) => (
            <BucketRefillRow
              refillBucketId={refillBucketId}
              setRefillBucketId={setRefillBucketId}
              refilledBucketsIds={refilledBucketsIds}
              bucket={bucket}
              drugs={drugs}
              startRefill={startRefill}
              refillComplete={refillComplete}
              removeFromList={removeFromList}
              bucketUnloading={bucketUnloading}
              startUnloading={startUnloading}
            />
          ))}
        {BOBuckets.length > 0 && (
          <>
            <div className="collapse-button-container">
              <hr />
              <Button
                onClick={() => setShowBO(!showBO)}
                aria-controls="machine-collapse-form"
                aria-expanded={showBO}
                variant="link"
              >
                {t('backOrdersAndOrdered')} ({BOBuckets.length}){' '}
                {showBO ? (
                  <FontAwesomeIcon icon="angle-up" />
                ) : (
                  <FontAwesomeIcon icon="angle-down" />
                )}
              </Button>
            </div>
            <Collapse in={showBO}>
              <div>
                {BOBuckets.map((bucket) => (
                  <BucketBORow
                    setRefillBucketId={setRefillBucketId}
                    startUnloading={startUnloading}
                    bucket={bucket}
                    drugs={drugs}
                  />
                ))}
              </div>
            </Collapse>
          </>
        )}
      </Modal.Body>
    </Modal>
  );
}

export default RefillBucketsModal;

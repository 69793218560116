import React from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { useTranslation } from 'react-i18next';
import './Modal.scss';

const FormModal = ({
  show,
  onHide,
  onSubmit,
  isSubmitting,
  title,
  children,
  modalClass,
  disabledSubmitButton,
}) => {
  const { t } = useTranslation();

  return (
    <div>
      <Modal show={show} onHide={onHide} centered dialogClassName={modalClass}>
        <Modal.Header closeButton>
          <Modal.Title>{title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>{children}</Modal.Body>
        <Modal.Footer>
          {onSubmit && (
            <Button
              variant="primary"
              onClick={onSubmit}
              disabled={isSubmitting || disabledSubmitButton}
            >
              {t('submit')}
            </Button>
          )}
          <Button variant="secondary" onClick={onHide}>
            {t('close')}
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default FormModal;

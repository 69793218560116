import { Bucket, DeactivatedReason, EditBucketInfos, Lot } from 'components/buckets/types';
import { Drug } from 'components/drugs/types';
import { BucketAction } from './reducers';
import types from './types';

export const createBucketRequest = (bucket: Bucket): BucketAction => ({
  type: types.CREATE_BUCKET_REQUEST,
  payload: { bucket },
});

export const createBucketResponse = (bucket: Bucket): BucketAction => ({
  type: types.CREATE_BUCKET_RESPONSE,
  payload: { bucket },
});

export const getBucketsRequest = (): BucketAction => ({
  type: types.GET_BUCKETS_REQUEST,
});

export const getBucketsResponse = (payload: { buckets: Bucket[] }): BucketAction => ({
  type: types.GET_BUCKETS_RESPONSE,
  payload,
});

export const updateBucketRequest = (id: string, bucket: Bucket): BucketAction => ({
  type: types.UPDATE_BUCKET_REQUEST,
  payload: { id, bucket },
});

export const updateBucketResponse = (bucket: Bucket): BucketAction => ({
  type: types.UPDATE_BUCKET_RESPONSE,
  payload: { bucket },
});

export const patchBucketRequest = (id: string, bucket: Partial<Bucket>): BucketAction => ({
  type: types.PATCH_BUCKET_REQUEST,
  payload: { id, bucket },
});

export const patchBucketResponse = (bucket: Bucket): BucketAction => ({
  type: types.PATCH_BUCKET_RESPONSE,
  payload: { bucket },
});

export const deleteBucketRequest = (bucketId: string): BucketAction => ({
  type: types.DELETE_BUCKET_REQUEST,
  payload: { bucketId },
});

export const deleteBucketResponse = (bucket: Bucket): BucketAction => ({
  type: types.DELETE_BUCKET_RESPONSE,
  payload: { bucket },
});

export const activateBucketRequest = (bucketId: string): BucketAction => ({
  type: types.ACTIVATE_BUCKET_REQUEST,
  payload: { bucketId },
});

export const activateBucketResponse = (bucket: Bucket): BucketAction => ({
  type: types.ACTIVATE_BUCKET_RESPONSE,
  payload: { bucket },
});

export const deactivateBucketRequest = (
  bucketId: string,
  reason: DeactivatedReason,
): BucketAction => ({
  type: types.DEACTIVATE_BUCKET_REQUEST,
  payload: { bucketId, reason },
});

export const deactivateBucketResponse = (bucket: Bucket): BucketAction => ({
  type: types.DEACTIVATE_BUCKET_RESPONSE,
  payload: { bucket },
});

export const loadBucketRequest = (): BucketAction => ({
  type: types.LOAD_BUCKET_REQUEST,
});

export const loadBucketResponse = (): BucketAction => ({
  type: types.LOAD_BUCKET_RESPONSE,
});

export const unloadBucketRequest = (bucketId: string): BucketAction => ({
  type: types.UNLOAD_BUCKET_REQUEST,
  payload: { bucketId },
});

export const unloadBucketResponse = (bucket: Bucket): BucketAction => ({
  type: types.UNLOAD_BUCKET_RESPONSE,
  payload: { bucket },
});

export const addLotRequest = (bucketId: string, lots: Lot[]): BucketAction => ({
  type: types.ADD_LOT_REQUEST,
  payload: { bucketId, lots },
});

export const addLotResponse = (bucket: Bucket, lots: Lot[]): BucketAction => ({
  type: types.ADD_LOT_RESPONSE,
  payload: { bucket, lots },
});

export const emptyLotsRequest = (bucketId: string): BucketAction => ({
  type: types.EMPTY_LOTS_REQUEST,
  payload: { bucketId },
});

export const emptyLotsResponse = (bucket: Bucket): BucketAction => ({
  type: types.EMPTY_LOTS_RESPONSE,
  payload: { bucket },
});

export const editQuantityRequest = (
  bucketId: string,
  newQuantity: number,
  justification: string,
): BucketAction => ({
  type: types.EDIT_QUANTITY_REQUEST,
  payload: { bucketId, newQuantity, justification },
});

export const editQuantityResponse = (bucket: Bucket): BucketAction => ({
  type: types.EDIT_QUANTITY_RESPONSE,
  payload: { bucket },
});

export const editBucketRequest = (
  bucketId: string,
  bucketInfos: EditBucketInfos,
): BucketAction => ({
  type: types.EDIT_BUCKET_REQUEST,
  payload: { bucketId, bucketInfos },
});

export const editBucketResponse = (bucket: Bucket): BucketAction => ({
  type: types.EDIT_BUCKET_RESPONSE,
  payload: { bucket },
});

export const inRefillListRequest = (bucketId: string, isIn: boolean): BucketAction => ({
  type: types.IN_REFILL_LIST_REQUEST,
  payload: { bucketId, isIn },
});

export const inRefillListResponse = (bucket: Bucket): BucketAction => ({
  type: types.IN_REFILL_LIST_RESPONSE,
  payload: { bucket },
});

export const importBucketListRequest = (bucketList, organizationName: string): BucketAction => ({
  type: types.IMPORT_BUCKETLIST_REQUEST,
  payload: { bucketList, organizationName },
});

export const importBucketListResponse = ({
  buckets,
  drugs,
}: {
  buckets: Bucket[];
  drugs: Drug[];
}): BucketAction => ({
  type: types.IMPORT_BUCKETLIST_RESPONSE,
  payload: { buckets, drugs },
});
